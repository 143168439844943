<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.title"
              placeholder="关键字/平替词/代工厂"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
<!--      <a-button type="primary" @click="add" style="margin-left:20px;" icon="plus">-->
<!--        新增-->
<!--      </a-button>-->
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('postStatus', 'color')" style="cursor: pointer">
          {{ record.status| dictName("postStatus") }}
        </a-tag>
      </template>
      <template slot="cheaperWordType" slot-scope="text, record">
        <a-tag :color="record.type | dictName('cheaperWordType', 'color')" style="cursor: pointer">
          {{ record.type| dictName("cheaperWordType") }}
        </a-tag>
      </template>
      <template slot="isTop" slot-scope="text, record">
        <a-switch :checked="record.isTop==1" @change="changeTopState(record)" :loading="loading"/>
      </template>
      <template slot="operation" slot-scope="text, record">
<!--        <a-button type="primary" icon="edit" size="small" @click="modify(record)">-->
<!--          修改-->
<!--        </a-button>-->
        <a-button type="danger" icon="delete" size="small" @click="deleteOne(record)" style="margin-left:10px">
          删除
        </a-button>
      </template>
    </a-table>
<!--    <modify-modal ref="modifyModal" @success="editSuccess"/>-->
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
// import modifyModal from "./modifyCheaperWordModal";

export default {
  name: "CheaperFeedbackList",
  mixins: [ListMixin],
  // components: {modifyModal},
  data() {
    return {
      listGetUrl: "cheaper/feedbackList",
      listMethod: "get",
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
        },
        {
          title: "标题",
          align: "center",
          width: 250,
          dataIndex: "title",
        },
        {
          title: "类型",
          align: "center",
          width: 250,
          dataIndex: "content",
        },
        {
          title: "替换词/说明",
          align: "center",
          width: 250,
          dataIndex: "remark",
        },
        {
          title: "状态",
          width: 80,
          dataIndex: "status",
          align: "center",
          scopedSlots: {customRender: "status"},
          sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order
        },
        {
          title: "用户",
          width: 150,
          align: "center",
          dataIndex: "userInfo.username",
        },
        {
          title: "提交时间",
          width: 150,
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "操作",
          width: 120,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    add() {
      this.$refs.modifyModal.showModal(undefined, 'new');
    },
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },

    deleteOne(record) {
      let that = this
      this.$confirm({
        title: '删除确认',
        content: '您确定要删除【' + record.title + '】吗？！',
        centered: true,
        onOk() {
          that.$post('cheaper/deleteFeedback', {id:record.id}).then(() => {
            that.$message.success('删除成功')
            that.search()
          })
        },
        onCancel() {
          that.selectedRowKeys = []
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
